/* .course-video-list {
    overflow: auto;
    height: 761.5px;
    width: auto;
    overflow-x: hidden;
    background-color: #0f0f0f;
} */

.list-group-item {
    padding-top: 0.2em;
    padding-bottom: 0.4em;
}

.course-video-item {
    margin-top: -31px;
    margin-right: 30%;
}

.course-video-time {
    margin-top: -26px;
    margin-left: 75%;
}

/* .video-item-group {
    width: 100%;
    background-color: #161616;
    color: white;
    border-bottom: 2px solid #161616;
} */

.video-item-group:hover {
    background-color: #292929;
    color: white;
}

.ant-list-item-meta-title {
    margin-bottom: 0px;
}

/* .ant-list-split .ant-list-item {
    border-bottom: 1px solid #696969;
} */

.ant-list-item-meta {
    height: 40px;
}

.ant-collapse-content-box {
    padding: 0
}