.user-review {
    background-color: white;
    padding-block: 74px;
}

.user-review .container {
  width: min(90%, 75rem);
  margin-inline: auto;
}

.user-review .testimonial-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f9f9ff;
}

.user-review .testimonial-card {
  display: flex;
  flex-direction: column;
  padding: 27px;
  border: 3px dashed #FEE285;
  border-radius: 10px;
  background-color: #FCF8FF;

  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.user-review .testimonial-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.user-review .testimonial-avatar {
  width: 74px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 1px solid #FEE285;
  margin-right: 10px;
}

.user-review .testimonial-name {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.user-review .testimonial-message {
  font-size: 18px;
  color: #555;
  font-weight: 100;
  margin: 0;
  line-height: 1.6;
}
  