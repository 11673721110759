.choose-we {
    padding-block: 74px;
    background-image: url("/public/assets/images/page/page_home/choose_we_main_bg.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    color: black;
}

.choose-we .container {
    width: min(90%, 75rem);
    margin-inline: auto;
}

.choose-we .container h2 {
    text-align: center;
    font-size: 36px;
    font-weight: normal;
    margin-bottom: 2rem;
}

.choose-we .container h3 {
    font-weight: normal;
    margin-top: 1.5rem;
    font-size: 22px;
}

.choose-we .container p {
    margin-top: 1.2rem;
    font-size: 18px;
}

.choose-we .container .img-wrapper {
    display: grid;
    place-content: center;
}

.choose-we .container .img-bg {
    border-radius: 50%;
   
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 262px;
    aspect-ratio: 1/1;
    display: grid;
    place-items: center;
    padding: 1rem;
}