
.ebook-card {
    border: 1px dotted #D1D1D1;
    background-color: white;
    border-radius: 8px;
    display: grid;
    cursor: pointer;
}

.ebook-card:hover {
    border: 1px dotted #D1D1D1;
    background-color: white;
    border-radius: 8px;
    display: grid;
    box-shadow: 1px 1px 15px 1px rgb(184, 184, 184);
    cursor: pointer;
}

.footer-card-btn {
    background-color: white;
    height: 35px;
    border-top: 1px dotted #CDCDCD;
    border-right: 1px dotted #CDCDCD;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FF7F00;
    cursor: pointer;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.footer-card-btn-left {
    background-color: white;
    height: 35px;
    border-top: 1px dotted #CDCDCD;
    border-right: 1px dotted #CDCDCD;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FF7F00;
    cursor: pointer;
    border-bottom-left-radius: 8px;
}

.footer-card-btn-right {
    background-color: white;
    height: 35px;
    border-top: 1px dotted #CDCDCD;
    border-right: 1px dotted #CDCDCD;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-bottom-right-radius: 8px;
}

.footer-card-btn-left:hover {
    height: 35px;
    border-top: 1px dotted #CDCDCD;
    border-right: 1px dotted #CDCDCD;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #CC6600;
    color: white;
}

.footer-card-btn-right:hover {
    height: 35px;
    border-top: 1px dotted #CDCDCD;
    border-right: 1px dotted #CDCDCD;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #CC6600;
    color: white;
}
