.course-group{
    padding-left: 6px;
    padding-right: 6px;
}

.btn-course-detail {
    width: 120px;
    margin-bottom: 15px;
    /* padding-bottom: 20px; */
}

.partition {
    box-sizing:border-box;
}

/* ------ pagination */

.paginationBttns {
    width: 80%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
    padding-left: 0rem;
    padding-top: 10px;
}

.paginationBttns {
    color: white;
}

.paginationBttns a {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    background-color: #8a8a8a;
    cursor: pointer;
    box-shadow: 2px 2px 3px #d3d3d3;
    font-size: 16px;
}

.paginationBttns a:hover {
    color: white;
    background-color: #444444;
    text-decoration: none;
}

.paginationActive a {
    color: white;
    background-color: #444444;
    text-decoration: none;
}

.previous svg {
    margin-top: -4px;
}

.next svg {
    margin-top: -4px;
}

.nextBttn {
    border-top-right-radius: 20px 20px;
    border-bottom-right-radius: 20px 20px;
    box-shadow: 2px 2px 3px #d3d3d3;
}

.previousBttn {
    border-bottom-left-radius: 20px 20px;
    border-top-left-radius: 20px 20px;
    box-shadow: 2px 2px 3px #d3d3d3;
}

.prediction-shop-card {
    border: 1px dotted #D1D1D1;
    background-color: white;
    border-radius: 8px;
    display: grid;
    cursor: pointer;
}

.prediction-shop-card:hover {
    border: 1px dotted #D1D1D1;
    background-color: white;
    border-radius: 8px;
    display: grid;
    box-shadow: 1px 1px 15px 1px rgb(184, 184, 184);
    cursor: pointer;
}