.our-service-card .wrapper {
    display: grid;
    grid-template: 1 / -1;
    /* max-width: 354px; */
   
    width: 100%;
    height: 100%;
   
}

.our-service-card {
    height: 100%;

   
}

.our-service-card .wrapper.small {
    /* display: grid;
    grid-template-areas: 1/-1; */
    /* max-width: 260px; */
    /* isolation: isolate; */
    
    /* width: 100%; */
    
}

.our-service-card .wrapper > * {
    grid-area: 1/-1;
    border-radius: 8px;
    
    
    
}

.our-service-card .wrapper > .front-card {
    background-image: url("/public/assets/images/page/page_home/our_service_front_bg.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
    
}

.our-service-card .wrapper > .front-card .section-image {
    text-align: center;
    padding-block: 24px;
}

.our-service-card .wrapper > .front-card .section-text {
    min-height: 100%;
    background-color: #00000080;
    padding: 8px;
    border-radius: 0 0 8px 8px;
}

.our-service-card .wrapper.small > .front-card .section-text {
    min-height: 100%;
    
}


.our-service-card .wrapper > .overlay {
    display: grid;
    place-items: center;
    opacity: 0;
    transform: scale(0.5);
    transition: 0.3s ease-out;
    background-image: url("/public/assets/images/page/page_home/our_service_back_bg.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}


.our-service-card .wrapper:hover > .overlay {
    opacity: 1;
    transform: scale(1);
    
}

.our-service-card .wrapper > .overlay .text {
    
    opacity: 0;
    transform: scale(0.5);
    transition: 0.3s ease-out 0.125s;
    
}

.our-service-card .wrapper:hover > .overlay .text{
    opacity: 1;
    transform: scale(1);
}

