.social-line {
    margin-top: 24px;
    display: flex;
    gap: 16px;

    .call-phone-icon, .line-icon, .facebook-icon,.wechat-icon {
        cursor: pointer;
        display: grid;
        place-items: center;
        width: 60px;
        height: 60px;
        background-color: #F3BB02;
        border-radius: 50%;
    }
}