.loading-screen {
    position: fixed;
    /* ตั้งให้อยู่ในตำแหน่งที่แน่นอนบนหน้าจอ */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* พื้นหลังสีดำจางๆ */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /* กำหนดให้อยู่บนสุด */
}

.loading-circle {
    position: relative;
    opacity: 0.5;
    animation: turn 4s linear infinite;
}

@keyframes turn {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
