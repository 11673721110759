.services {
    min-height: 1111px;
    background-image: linear-gradient(to bottom, #73737300, #D9D9D9CC),url("/public/assets/images/page/page_home/our_service_bg.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding-bottom: 74px;
    padding-top: 66px;
    color: white;
}

.services .container {
    width: min(90%, 75rem);
    margin-inline: auto;
}
