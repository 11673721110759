.paginate-container {
    margin-top: 16px;
    text-align: center;
    display: flex;
    justify-content: center;
    .ant-pagination-item {
        /* // border: 1px solid black !important; */
        background-color: #E1E1E1 !important;
        color: black;
        margin-right: 8px !important;
    }
      
    .ant-pagination-item-active {
        border: 1px solid transparent !important;
        background-color: #F8EAFF !important;
        
        color: #F3BB02 !important;
    }
    
    .ant-pagination-item-disabled {
      background-color: #d9d9d9;
    }
    
    .ant-pagination-item:hover {
      background-color: #e6f7ff;
      border-color: #F3BB02 !important;
    }
    
    .ant-pagination-item a {
      color: #F3BB02;
     }

  
    
    
    .ant-pagination-prev,
    .ant-pagination-next {
        margin-right: 8px !important; /* Adjust this value to set the desired gap */
        /* // border: 1px solid black !important; */
        background-color: #EEEEEE !important;
    }
}
