:where(.css-dev-only-do-not-override-ocxv9n).ant-modal.modalRecommend .ant-modal-content{
    background-color: rgba(255, 255, 255, 0.5);
}

.menu {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-top: 4px;
    padding-bottom: 4px;
}

.menu:hover>div>div {
    background-color: #CC6600;
    cursor: pointer;
}

.menu-img {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FF7F00;
    padding: 6px;
    border-radius: 50px;
    cursor: pointer;
}

.menu-text {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #565656;
    padding: 8px;
    border-radius: 50px;
    width: 150px;
}

.announce-card {
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #EEEEEE;
    background-color: white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    /* cursor: pointer; */
}

.announce-card:hover {
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #EEEEEE;
    background-color: white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    /* cursor: pointer; */
    box-shadow: 1px 1px 15px 1px rgb(184, 184, 184);
}

@keyframes slide-bottom {
    0% {

        transform: translateY(0px) scale(0.1);
    }

    50% {
        transform: translateY(-30px) ;
    }

    80% {
        left: 0;
        transform: translateY(90px);
    }

    85% {
        left: 0;
        transform: translateY(60px);
    }

    90% {
        left: 0;
        transform: translateY(90px);
    }

    95% {
        left: 0;
        transform: translateY(60px);
    }

    100% {
        left: 0;
        transform: translateY(70px);
    }
}

@keyframes slide-left {
    0% {
        bottom: 0%;
        opacity: 0;
        transform: translateY(20px);
    }

    60% {
        bottom: 0;
        opacity: 1;
        transform: translateY(-10px);
    }

    70% {
        bottom: 0;
        opacity: 1;
        transform: translateY(10px);
    }

    80% {
        bottom: 0;
        opacity: 1;
        transform: translateY(-5px);
    }

    90% {
        bottom: 0;
        opacity: 1;
        transform: translateY(5px);
    }

    100% {
        bottom: 0;
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes slide-right {
    0% {
        left: 100%;
        opacity: 0;
        transform: translateX(200px);
    }

    60% {
        left: 0;
        opacity: 1;
        transform: translateX(30px);
    }

    70% {
        left: 0;
        opacity: 1;
        transform: translateX(-30px);
    }

    80% {
        left: 0;
        opacity: 1;
        transform: translateX(15px);
    }

    90% {
        left: 0;
        opacity: 1;
        transform: translateX(-15px);
    }

    100% {
        left: 0;
        opacity: 1;
        transform: translateX(0px);
    }
}

.moving {
    position: relative;
    /* top: 50%; */
    /* ให้ตำแหน่งตรงกลางแนวตั้ง */
    /* transform: translateX(-100%); */
    /* จัดตำแหน่งให้อยู่กลางแนวตั้ง */
    animation-fill-mode: forwards;
    /* opacity: 0; */
    /* เริ่มต้นที่ซ่อนอยู่ */
}

.my-image {
    top: -50%;
    animation: slide-bottom 1s ease forwards;
}

.my-text {
    bottom: -200%;
    animation: slide-left 0.5s ease forwards 1s;
}

.my-menu1 {
    left: 300%;
    animation: slide-right 0.4s ease forwards 1.5s;
}

.my-menu2 {
    left: 300%;
    animation: slide-right 0.4s ease forwards 1.9s;
}

.my-menu3 {
    left: 300%;
    animation: slide-right 0.4s ease forwards 2.3s;
}

.my-menu4 {
    left: 300%;
    animation: slide-right 0.2s ease forwards 2.7s;
}

.my-menu5 {
    left: 300%;
    animation: slide-right 0.2s ease forwards 3.1s;
}
.my-menu6 {
    left: 300%;
    animation: slide-right 0.2s ease forwards 3.5s;
}