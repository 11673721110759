.prediction-detail {
    /* margin-top: 80px; */
}


.prediction-detail .header {
    padding-block: 24px;
    background-image: linear-gradient(to right,#F19609,#F1672A);
}

.prediction-detail .header .container, .prediction-detail .detail .container{
    width: min(90%, 75rem);
    margin-inline: auto;
}

.prediction-detail .detail .main-bg {
    background-color: #222222;
    padding-block: 37px;
}

.prediction-detail .header .container h1 {
    font-size: 48px;
    color: white;
}

.prediction-detail .detail {
    
    padding-bottom: 74px;
    background-color: #303030;
}

.prediction-detail .detail .detail-text {
    font-size: clamp(15px, 15px + 2vw, 36px);
 
    font-weight: 700;
    color: white;
    margin-left: 2rem;
}

.prediction-detail .detail .detail-text > * {
    margin-top: 6px;
    display: flex;
    
}

.prediction-detail .detail .detail-text .title{
    width: min(230px, 40%);
    flex-shrink: 0;
}

.prediction-detail .detail .detail-text .highlight{
    color: #FEE285;
}

.prediction-detail .detail .container .description {
    margin-top: 37px;
    font-size: 24;
    color: white;
}



@media (max-width: 992px) {
    .prediction-detail .detail .detail-text {
        
        margin-left: 0rem;
    }
}