.intro {
    min-height: 468px;
    background-image: radial-gradient(circle, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 1)),url("/public/assets/images/page/page_home/intro_bg.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding-block: 74px;
    color: white;
}

.intro .container {
    width: min(90%, 75rem);
    margin-inline: auto;
}

.intro .container .intro-1 {
    display: flex;
    align-items: center;
    gap: 36px;
}

@media (max-width: 500px) {
    .intro .container .intro-1 {
        flex-direction: column;
    }
}