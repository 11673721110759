.card {
  width: 156px;
  height: 234px;
  background-color: #f0f0f0;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
}

.card-chance-occurrence {
  width: 300px;
  height: 300px;
  background-color: #f0f0f0;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
}

@keyframes rotateCard {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }

  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes rotateCircle {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    opacity: 0;
    transform: scale(1.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.rotate {
  /* animation: rotateCard 2s linear infinite; */
  animation: rotateCard 1s infinite;
}

.rotate-chance-occurrence {
  animation: rotateCircle 1s linear infinite;
}

.predict-result-board {
  transform: scale(3);
  animation: scale 1s forwards cubic-bezier(0.5, 1, 0.89, 1);
}

@keyframes scale {
  100% {
    transform: scale(1);
  }
}

.predict-result-text {
  display: inline-block;
  opacity: 0.5;
  filter: blur(10);
}

.predict-result-text {
  animation: fade-in 0.4s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}


@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}