.payment-method:hover {
    background-color: #FF7F00;
    color: white;
}

.payment-method {
    cursor: pointer;
    padding: 15px;
}

.Button__StyledButton-sc-1wkqg8n-0 {
    background-color: #FF7F00;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    text-align: center;
    width: 100%;
}

.payment-order-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 19px;
    max-height: 40px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    /* height: 40px; */
}

.payment-order-subtitle {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 19px;
    /* fallback */
    max-height: 100px;
    /* fallback */
    -webkit-line-clamp: 5;
    /* number of lines to show */
    -webkit-box-orient: vertical;
    /* height: 80px; */
}