.shot-of-ideas {
    padding-top: 48px;
    padding-bottom: 74px;
    background-image: linear-gradient(to top, #FFFFFF, #ABABAB) 
}

.shot-of-ideas .container {
    width: min(90%, 75rem);
    margin-inline: auto;
}

.shot-of-ideas .container .wrapper > .overlay p {
    margin-top: 1rem;
}

.shot-of-ideas .blog-detail {
        
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    word-wrap: break-word;
    margin-bottom: 8px;
    height: 140px;
    /* font-weight: 700;s */
}

.shot-of-ideas .ant-card {
    border-color: transparent !important;
}

.shot-of-ideas .ant-card .ant-card-cover {
    margin-inline: 0px !important;
    position: relative;
}

.shot-of-ideas .ant-card .ant-card-cover::before {
    content: "";
    position: absolute;
    bottom: -5px;
    height: 5px;
    width: 100%;
}

.shot-of-ideas .ant-card.i0 .ant-card-cover::before {
    background-color: var(--blue-clr);
}

.shot-of-ideas .ant-card.i1 .ant-card-cover::before {
    background-color: var(--green-clr);
}

.shot-of-ideas .ant-card.i2 .ant-card-cover::before {
    background-color: var(--pink-clr);
}

.shot-of-ideas .ant-card.i3 .ant-card-cover::before {
    background-color: var(--orange-clr);
}


.shot-of-ideas .ant-card .ant-card-cover > *, .shot-of-ideas .ant-card .ant-card-body,.shot-of-ideas .ant-card{
    /* border-radius: 0 !important; */
    background-color: transparent !important;
}

.shot-of-ideas .ant-card .ant-card-body{
    padding: 8px !important;
}

.shot-of-ideas .ant-card-meta-title {
    margin-bottom: 0px !important;
}

.shot-of-ideas .ant-card.i0 .ant-card-meta-title {
    color: var(--blue-clr) !important;
}

.shot-of-ideas .ant-card.i1 .ant-card-meta-title {
    color: var(--green-clr) !important;
}

.shot-of-ideas .ant-card.i2 .ant-card-meta-title {
    color: var(--pink-clr) !important;
}

.shot-of-ideas .ant-card.i3 .ant-card-meta-title {
    color: var(--orange-clr) !important;
}
