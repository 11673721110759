.youtube {
    min-height: 224px;
    background-image: url("/public/assets/images/page/page_home/youtube_bg.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding-block: 44px;
    color: black;
}

.youtube .container {
    width: min(90%, 491px);
    margin-inline: auto;
    text-align: center;
}

.youtube .container .contact {
    max-width: 396px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    box-shadow: 0px 4px 8px #00000040;
    border-radius: 10px;
    margin-inline: auto;
    margin-top: 8px;
}

.youtube .container .contact .left {
    display: flex;
    align-items: center;
    padding: 14px 16px;
    gap: 8px;
    flex-wrap: wrap;
}