.swiper-slide img {
    display: block;
    object-fit: cover;
}

.swiper-slide {
    width: auto;
}

.swiper-button-prev {
    background-color: #F3BB02;
    color: white;
    border-radius: 10px 10px 10px 10px;
    padding-top: 35px;
    padding-bottom: 35px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: -30px;
    
}

.swiper-button-next {
    background-color: #F3BB02;
    color: white;
    border-radius: 10px 10px 10px 10px;
    padding-top: 35px;
    padding-bottom: 35px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: -30px;
}

.swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    background-color: white;
}

.swiper-pagination-bullet-active {
    background-color: white;
}