.footer-detail-board {
    background-color: black;
    color: white;
    border-radius: 0%;
}

.complaint-board {
    width: 200px;
}

.complaint-item {
    padding: 5px;
}

.complaint-item:hover {
    background-color: #429321;
    padding: 5px;
    margin: 0px;
    cursor: pointer;
}

.btn-consult {
    display: flex;
    align-items: center; 
    justify-content: center; 
    background-color: #429321; 
    padding: 8px;
    border-radius: 50px; 
    width: 150px;
    color: black;
}

.btn-consult:hover {
    background-color: #429321;
    cursor: pointer;
    color: white;
}

.footer .social-line {
    margin-top: 24px;
    display: flex;
    gap: 16px;

    .call-phone-icon, .line-icon, .facebook-icon,.wechat-icon {
        cursor: pointer;
        display: grid;
        place-items: center;
        width: 60px;
        height: 60px;
        background-color: #F3BB02;
        border-radius: 50%;
    }
}