.course-overview-course-shop-container {
    /* padding: 24px; */
    background-color: #191919;
    color: white;
    /* height: 400px; */
}

.course-overview-classroom-container {
    /* padding-right: 24px; */
    /* padding-left: 24px; */
    /* padding-top: 24px; */

    background-color: #191919;
    color: white;
    /* height: 135px; */
}