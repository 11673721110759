.products {
    /* margin-top: 80px; */
}

.products .header {
    padding-block: 24px;
    background-image: linear-gradient(to right,#F19609,#F1672A);
}

.products .header .container, .products .products-list .container {
    width: min(90%, 75rem);
    margin-inline: auto;
}

.products .header .container h1 {
    font-size: 48px;
    color: white;
}



.products .products-list {
    padding-top: 37px;
    padding-bottom: 74px;
    min-height: 500px;
}

.products .products-list .total-and-search {
    margin-bottom: 37px;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
}

.products .products-list .total-and-search .ant-input,
.products .products-list .total-and-search .ant-input-search-button {
    height: 48px !important;
    font-size: 24px !important;
}

.products .ant-card .ant-card-cover {
    margin-inline: 0px !important;
    position: relative;
}

.products .ant-card .ant-card-cover > *, .products .ant-card .ant-card-body,.products .ant-card{
    /* border-radius: 0 !important; */
    
}

.products .ant-card .ant-card-body{
    padding: 8px !important;
    
}

.products .ant-card-meta-title {
    margin-bottom: 0px !important;
}


.products .title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    word-wrap: break-word;
    font-weight: normal;
    margin-bottom: 8px;
    height: 70px;
    /* font-weight: 700;s */
}



/*Pop card */

.products .wrapper {
    display: grid;
    grid-template: 1fr/1fr;
    align-items: flex-start;
    /* max-width: 354px; */
    height: auto;
    width: 100%; /* or a specific width */

   
}

.products .wrapper > * {
    grid-area: 1/-1;
    border-radius: 8px;
    overflow: hidden;    
}

.products .wrapper > .front-card {
    /* background-image: url("/public/assets/images/page/page_home/our_service_front_bg.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; */
    
}


.products .wrapper > .overlay {
    display: grid;
    grid-template: 1fr/1fr;
    /* max-width: 354px; */
    isolation: isolate;
    /* place-items: center; */
    opacity: 0;
    transform: scale(0.5);
    transition: 0.3s ease-out;
  
}

.products .wrapper:hover > .overlay {
    opacity: 1;
    transform: scale(1);
    
}

.products .wrapper:hover > .front-card {
    box-shadow: 4px 4px 12px #00000040;
    transition: 0.3s ease-out;
}

.products .wrapper > .overlay .text {
    
    opacity: 0;
    transform: scale(0.5);
    transition: 0.3s ease-out 0.125s;
    
}

.products .wrapper:hover > .overlay .text{
    opacity: 1;
    transform: scale(1);
}

.products .wrapper > .overlay > * {
    grid-area: 1/-1;
    overflow: hidden;

}

.products .wrapper .overlay .detail-title {
    font-weight: bolder;
    font-size: 24px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    word-wrap: break-word;
    
    height: 75px;
}

.products .wrapper .overlay .detail {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 8;
    overflow: hidden;
    word-wrap: break-word;
    font-weight: normal;
    margin-bottom: 14px;
    height: 190px;
    /* font-weight: 700;s */
}